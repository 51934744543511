import Main from "src/layouts/Main";

import Hero from "src/components/static/Hero";
import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/layout";
import { Feature } from "src/components/static/Feature";
import { FcComments, FcPrivacy, FcTimeline, FcVideoCall } from "react-icons/fc";
import { StatCard } from "src/components/static/StatCard";
import { DiscountContext } from "../components/context/DiscountContext";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import React, { useContext } from "react";

export default function index() {
  const { t } = useTranslation("common");

  const { discount } = React.useContext(DiscountContext);

  const price =
    process.env.NEXT_PUBLIC_COMPANY_NAME === "legalis"
      ? `${discount ? discount.hourPrice * 2 : 2300} / per time`
      : "kr. 2.100 eks. mva. (2.625 inkl. mva.) for fullmektig, kr. 2.300 eks. mva. pr. time (2.875 inkl. mva.) for advokat, og kr. 2.450 eks. mva. (3.062 inkl. mva.) for partner";

  return (
    <Main noPadding>
      <div className="root">
        <Hero />
        <Box
          as="section"
          maxW="5xl"
          mx="auto"
          py="12"
          px={{ base: "6", md: "8" }}
        >
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacingX="10"
            spacingY={{ base: "8", md: "14" }}
          >
            <Feature title="Sikkert" icon={<FcPrivacy />}>
              Våre systemer holder informasjonen din trygg. Vi bruker også
              BankID ved alle innlogginger, noe som gjør at du og dine data
              holdes trygge.
            </Feature>
            <Feature title="Oversiktlig" icon={<FcTimeline />}>
              Vår pris er alltid {price}, uavhengig av hvilken type bistand du
              trenger.
            </Feature>
            <Feature title="Chat med advokat" icon={<FcComments />}>
              Alle våre kunder får etter bestilling tilgang på direktechat med
              sin advokat. Her kan man avtale nye møter, laste opp filer eller
              diskutere enkelte ting ved saken uten kostnad.
            </Feature>
            <Feature title="Bestill møter i farta" icon={<FcVideoCall />}>
              Våre advokater kan du møte når du vil, enten på telefon eller
              videochat i nettleseren. Du bestiller timer selv, gang for gang
              til vår faste timepris.
            </Feature>
          </SimpleGrid>
        </Box>
        <Box as="section" pb="20">
          <Box bg="legalis.500" pt="20" pb="20" mt="10">
            <Box
              maxW={{ base: "xl", md: "7xl" }}
              mx="auto"
              px={{ base: "6", md: "8" }}
            >
              <Box mb="16" color="white" maxW="xl">
                <Heading
                  size="2xl"
                  letterSpacing="tight"
                  fontWeight="extrabold"
                  lineHeight="normal"
                >
                  {t("delivered-by")}
                </Heading>
                <Text fontSize="lg" mt="4" fontWeight="medium">
                  Samtidig som at vi er opptatte av å gi våre kunder bedre
                  tjenester gjennom teknologi og forenkling, er vi et
                  fulletablert advokatfirma i Oslo. Den personlige oppfølgingen
                  vil du alltid få.
                </Text>
              </Box>
            </Box>
          </Box>
          <Box mt="-20">
            <Box
              maxW={{ base: "xl", md: "7xl" }}
              mx="auto"
              px={{ base: "6", md: "8" }}
            >
              <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing="6">
                <StatCard title="Antall kunder" value="5000+">
                  Siden oppstarten i 1998 har vi hjulpet tusenvis av kunder i
                  vanskelige situasjoner.
                </StatCard>
                <StatCard
                  title="Anmeldelser i øvre sjikte"
                  value="★★★★★"
                  size="small"
                >
                  Vår beste referanser er våre kunder, som du kan lese
                  tilbakemeldingene fra hos Google og Facebook.
                </StatCard>
                <StatCard title="Erfaring siden" value="1998">
                  Med over 20 år i bransjen har vi solid erfaring på huset innen
                  de fleste rettsområder.
                </StatCard>
                <StatCard title="Antall ansatte advokater" value="35+">
                  Vi er i stadig vekst, og har i dag 35 advokater.
                </StatCard>
              </SimpleGrid>
            </Box>
          </Box>
        </Box>
      </div>
    </Main>
  );
}
export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
      // Will be passed to the page component as props
    },
  };
}
