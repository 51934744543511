import { Media } from "src/components/Media";

import React, { useContext } from "react";
import Footer from "src/components/navigation/Footer";
import { useRouter } from "next/router";
import SideBarNav from "../components/navigation/SideBarNav";
import { UserContext } from "../components/context/UserContext";
import { Box } from "@chakra-ui/react";

export default function Main({ children, wide, noPadding = false }: any) {
  const router = useRouter();
  return (
    <Box bg={"#e2e8f0"} overflowX={"hidden"} maxW={"100vw"}>
      <Media at="xs">
        <SideBarNav noPadding={noPadding}>{children}</SideBarNav>
      </Media>
      <Media greaterThan="xs">
        <SideBarNav noPadding={noPadding}>{children}</SideBarNav>
      </Media>
    </Box>
  );
}
